import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { reactive, watch } from 'vue';
import type { FormDataConvertible } from '~/shared/types/global';
import { hasFiles } from '~/shared/utilities/hasFiles';
import { objectToFormData } from '~/shared/utilities/formData';
import useSleep from '~/shared/composables/useSleep';

type FormDataType = object;

export type Method =
  | 'get'
  | 'post'
  | 'put'
  | 'patch'
  | 'delete'
  | 'GET'
  | 'HEAD'
  | 'PATCH'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'head'
  | 'connect'
  | 'options'
  | 'trace'
  | undefined;

export type ISubmitOptions = Partial<
  RequestInit & {
  onBefore: () => void;
  onStart: () => void;
  onFinish: () => void;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}
>;

interface FormProps<TForm extends FormDataType> {
  isDirty: boolean;
  errors: Partial<Record<keyof TForm, string[]>>;
  hasErrors: boolean;
  processing: boolean;
  sleep: number;

  data(): TForm;

  transform(callback: (data: TForm) => object): this;

  defaults(): this;

  defaults(field: keyof TForm, value: FormDataConvertible): this;

  // eslint-disable-next-line @typescript-eslint/unified-signatures
  defaults(fields: Partial<TForm>): this;

  reset(...fields: (keyof TForm)[]): this;

  clearErrors(...fields: (keyof TForm)[]): this;

  setError(field: keyof TForm, value: string): this;

  setError(errors: Record<keyof TForm, string>): this;

  submit(method: Method, url: string, options?: Partial<ISubmitOptions>): void;

  get(url: string, options?: Partial<ISubmitOptions>): void;

  post(url: string, options?: Partial<ISubmitOptions>): void;

  put(url: string, options?: Partial<ISubmitOptions>): void;

  patch(url: string, options?: Partial<ISubmitOptions>): void;

  delete(url: string, options?: Partial<ISubmitOptions>): void;
}

export type Form<TForm extends FormDataType> = TForm &
  FormProps<TForm>;

export default function useForm<TForm extends FormDataType>(
  data: TForm | (() => TForm)
): Form<TForm> {
  let defaults = typeof data === 'object' ? cloneDeep(data) : cloneDeep(data());
  let transform = (data: TForm | (() => TForm)) => data;

  const form = reactive({
    ...cloneDeep(defaults),
    isDirty: false,
    errors: {},
    hasErrors: false,
    processing: false,
    sleep: 0,
    data() {
      return (Object.keys(defaults) as Array<keyof TForm>).reduce(
        (carry, key) => {
          
          carry[key] = this[key];
          return carry;
        },
        {} as Partial<TForm>
      ) as TForm;
    },
    
    transform(callback: (data: TForm) => object): this {
      
      transform = callback;

      return this;
    },
    defaults(
      fieldOrFields?: keyof TForm | Partial<TForm>,
      maybeValue?: FormDataConvertible
    ) {
      if (typeof data === 'function') {
        throw new Error(
          'You cannot call `defaults()` when using a function to define your form data.'
        );
      }

      if (typeof fieldOrFields === 'undefined') {
        defaults = this.data();
      } else {
        defaults = Object.assign(
          {},
          cloneDeep(defaults),
          typeof fieldOrFields === 'string'
            ? { [fieldOrFields]: maybeValue }
            : fieldOrFields
        );
      }

      return this;
    },
    
    reset(...fields: (keyof TForm)[]): this {
      const resolvedData =
        typeof data === 'object' ? cloneDeep(defaults) : cloneDeep(data());
      const clonedData = cloneDeep(resolvedData);
      if (fields.length === 0) {
        defaults = clonedData;
        Object.assign(this, resolvedData);
      } else {
        Object.keys(resolvedData)
          
          .filter((key) => fields.includes(key))
          .forEach((key) => {
            
            defaults[key] = clonedData[key];
            
            this[key] = resolvedData[key];
          });
      }

      return this;
    },
    setError(
      fieldOrFields: keyof TForm | Record<keyof TForm, string>,
      maybeValue?: string
    ) {
      Object.assign(
        this.errors,
        typeof fieldOrFields === 'string'
          ? { [fieldOrFields]: maybeValue }
          : fieldOrFields
      );

      this.hasErrors = Object.keys(this.errors).length > 0;

      return this;
    },
    
    clearErrors(...fields: (keyof TForm)[]): this {
      this.errors = Object.keys(this.errors).reduce(
        (carry, field) => ({
          ...carry,
          
          ...(fields.length > 0 && !fields.includes(field)
            ?
            { [field]: this.errors[field] }
            : {}),
        }),
        {}
      );

      this.hasErrors = Object.keys(this.errors).length > 0;

      return this;
    },
    async submit(method: Method, url: string, options: ISubmitOptions = {}) {
      this.processing = true;
      this.clearErrors();

      if (options.onBefore) {
        options.onBefore();
      }

      let data = transform(this.data());

      if (hasFiles(data) && !(data instanceof FormData)) {
        data = objectToFormData(data);
      }

      const headers = {
        ...options.headers,
        accept: 'application/json',
      };

      await useSleep(this.sleep);

      try {
        if (options.onStart) {
          options.onStart();
        }

        const client = useSanctumClient();
        const response = await client(url, {
          method,
          ...options,
          ...(method === 'get' ? { query: data } : { body: data }),
          headers,
        });

        if (options.onSuccess) {
          options.onSuccess(response);
        }
      } catch (error: any) {
        if (error.statusCode === 422 && error.data.errors) {
          this.setError(error.data.errors);
        }

        if (options.onError) {
          options.onError(error);
        }
      } finally {
        this.processing = false;

        if (options.onFinish) {
          options.onFinish();
        }
      }
    },
    get(url: string, options: ISubmitOptions = {}): void {
      this.submit('get', url, options);
    },
    post(url: string, options: ISubmitOptions = {}): void {
      this.submit('post', url, options);
    },
    put(url: string, options: ISubmitOptions = {}): void {
      this.submit('put', url, options);
    },
    patch(url: string, options: ISubmitOptions = {}): void {
      this.submit('patch', url, options);
    },
    delete(url: string, options: ISubmitOptions = {}): void {
      this.submit('delete', url, options);
    },
  });

  watch(form, () => (form.isDirty = !isEqual(form.data(), defaults)), {
    immediate: true,
    deep: true,
  });

  return form;
}